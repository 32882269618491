<template>
  <v-row class="px-2 ml-md-0">
    <h2 class="mb-0">{{ $t("resetPassword") }}</h2>
    <span>{{ $t("createYourNewPassword") }}</span>
    <v-col cols="12" class="p-0">
      <v-text-field
        :label="$t('newPassword')"
        required
        :type="showPassword ? 'text' : 'password'"
        :hide-details="errors.length === 0"
        color="#59D79D"
        :error-count="errors.length"
        :error-messages="errors"
        v-model="password"
      ></v-text-field>
      <v-icon v-show="showPassword" @click="onShowPassword" class="absolute">mdi-eye</v-icon>
      <v-icon v-show="!showPassword" @click="onShowPassword" class="absolute">mdi-eye-off</v-icon>
    </v-col>
    <v-col cols="12" class="p-0">
      <v-text-field
        :label="$t('confirmNewPassword')"
        required
        :type="showConfirmPassword ? 'text' : 'password'"
        :hide-details="errorsConfirm.length === 0"
        color="#59D79D"
        :error-count="errorsConfirm.length"
        :error-messages="errorsConfirm"
        v-model="confirmPassword"
      ></v-text-field>
      <v-icon v-show="showConfirmPassword" @click="onShowConfirmPassword" class="absolute">mdi-eye</v-icon>
      <v-icon v-show="!showConfirmPassword" @click="onShowConfirmPassword" class="absolute">mdi-eye-off</v-icon>
    </v-col>
    <v-col offset="6" cols="6">
      <div class="float-end">
        <general-button :loading="loading" :message="$t('resetPassword')" size="small"  :action="onSubmit"/>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';

export default {
  name: 'ResetPassword',
  components: { GeneralButton },
  props: {
    token: {
      type: String
    }
  },
  mixins: [errorDialog],
  data () {
    return {
      userToken: this.token,
      errors: [],
      errorsConfirm: [],
      loading: false,
      confirmPassword: null,
      password: null,
      showPassword: false,
      showConfirmPassword: false
    };
  },
  methods: {
    onSubmit () {
      this.errors = [];
      this.errorsConfirm = [];
      const passwordValidation = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\-_.;,\\])[A-Za-z\d@$!%*#?&\-_.;,\\]{8,}$/;
      if (
        this.confirmPassword &&
        this.password &&
        this.password.match(passwordValidation) &&
        this.password === this.confirmPassword
      ) {
        this.loading = true;
        const url = `${process.env.VUE_APP_BASE_URL}/api/password/reset/${this.userToken}`;
        this.loading = true;
        const data = {
          password: this.password,
          password_confirmation: this.confirmPassword,
          pass_reset_token: this.userToken
        };
        axios
          .post(url, data)
          .then(() => {
            this.loading = false;
            this.$router.push({ name: 'auth1.sign-in1' });
            this.$swal.fire({
              title: this.$t('awesome'),
              text: this.$t('passwordSuccessfullyReset'),
              confirmButtonColor: '#19AD93'
            });
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      }
      if (
        this.password !== this.confirmPassword &&
        this.password &&
        this.confirmPassword
      ) {
        this.errors.push(this.$t('passwordsMustMatch'));
      }
      if (!this.password.match(passwordValidation)) {
        this.errors.push(this.$t('invalidPassword'));
      }
      if (!this.password) {
        this.errors.push(this.$t('passwordRequired'));
      }
      if (!this.confirmPassword) {
        this.errorsConfirm.push(this.$t('passwordRequired'));
      }
    },
    onShowPassword () {
      this.showPassword = !this.showPassword;
    },
    onShowConfirmPassword () {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }
};
</script>

<style scoped>
  .relative {
    position: relative;
    width: 100%;
  }

  .absolute {
    position: absolute;
    top: 20px;
    right: 12px;
  }

  @media(max-width: 768px) {
    .absolute {
      top: 12px;
      right: 12px;
    }
  }
</style>
